import React from 'react';
import { imgs } from './constants';
import 'react-photoswipe/lib/photoswipe.css';
import { PhotoSwipeGallery } from 'react-photoswipe';

export class Index extends React.Component {
  render() {
    return (
      <section className="start">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3"></div>
          </div>
        </div>
      </section>
    );
  }
}

export class AboutUs extends React.Component {
  render() {
    return (
      <section className="start">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 center">
              <p>A Szinán 21 Kft 2013-ban alakult vállalkozás. Az építőipar területén végzett fővállalkozói valamint projektmenedzsmenti tevékenységünk kiterjed a vállalati, köz- és magánszféra kiszolgálására.</p>
              <br/><br/><br/><br/>
              <p>Kamarai nyilvántartás száma: 11A80324</p>
              <p>KÜJ: 103557063</p>
              <p>KTJ: 102497387</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      galleryItems: imgs.map(function(item) {
        return {
          src: item.img,
          thumbnail: item.img,
          w: item.width,
          h: item.height,
          title: item.title
        };
      }),
      options: {}
    };
  }

  openPhotoSwipe(e) {
    e.preventDefault();
    this.setState({
      isOpen: true,
      options: {
        closeOnScroll: false
      }
    });
  }

  handleClose() {
    this.setState({
      isOpen: false
    });
  }

  getThumbnailContent(item) {
    return (
      <img src={item.thumbnail} alt="thumbnail" width={320} height={240}/>
    );
  }

  render() {
    return (
      <section className="gallery">
        <a name="gallery"></a>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 center">
              <h1>Galéria</h1>
              <PhotoSwipeGallery items={this.state.galleryItems} thumbnailContent={this.getThumbnailContent} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export class Contact extends React.Component {
  render() {
    return (
      <section className="contact">
        <a name="contact"></a>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 center">
              <p>Székhely: <a href="https://www.google.hu/maps/place/Budapest,+V%C3%A1ci+u.+24,+1052/@47.4938215,19.0505525,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc41330c6125:0x14bee7b1eb470144!8m2!3d47.4938179!4d19.0527412" rel="noopener noreferrer" target="_blank">1052 Budapest, Váci u. 24.</a></p>
              <p>Telefon: <a href="callto:+36209650940">+36 209 650 940</a></p>
              <p>Email: <a href="mailto:torok.b@szinan.hu">torok.b@szinan.hu</a></p>
              <p>Web: <a href="http://szinan.hu">szinan.hu</a></p>
              <p>Számlaszám (CIB): 10700323-68690766-51100005</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
