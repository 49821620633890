import React from 'react';

class FooterComponent extends React.Component {
  render() {
    return (
      <footer>
        <div className="container-fluid center">
          <div className="row">
            <div className="col-sm-12">
              <p>Adószám: 24290511-2-41<br/>Cégjegyzékszám: 01-09208491</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

FooterComponent.defaultProps = {
};

export default FooterComponent;
