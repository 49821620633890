import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as SubPage from './subpages';
import Header from './header';
import Footer from './footer';

class AppComponent extends React.Component {
  render() {
    return (
      <Router>
        <div className="wrapper">
          <Header />
          <main>
            {/* <Route exact path="/" component={SubPage.Index} /> */}
            <Route path="/" exact component={SubPage.AboutUs} />
            <Route path="/galeria" component={SubPage.Gallery} />
            <Route path="/kapcsolat" component={SubPage.Contact} />
          </main>
          <Footer />
        </div>
      </Router>
    );
  }
}

AppComponent.defaultProps = {
};

export default AppComponent;
