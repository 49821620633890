module.exports = {
  imgs: [
    {
      img: require('../images/kabeltarto-hid-acelszerkezet.jpg'),
      thumbnail: require('../images/thumbnail-kabeltarto-hid-acelszerkezet.jpg'),
      title: 'Kábeltartó híd acélszerkezet',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/csohid-bontas-2.jpg'),
      thumbnail: require('../images/thumbnail-csohid-bontas-2.jpg'),
      title: 'Csőhíd bontás 2',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/csohid-bontas-1.jpg'),
      thumbnail: require('../images/thumbnail-csohid-bontas-1.jpg'),
      title: 'Csőhíd bontás 1',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/plaza_felujitas_2.jpg'),
      thumbnail: require('../images/thumbnail-plaza_felujitas_2.jpg'),
      title: 'Plaza felújítás',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/plaza_felujitas_1.jpg'),
      thumbnail: require('../images/thumbnail-plaza_felujitas_1.jpg'),
      title: 'Plaza felújítás',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/uzemi_epulet_rakosliget.jpg'),
      thumbnail: require('../images/thumbnail-uzemi_epulet_rakosliget.jpg'),
      title: 'Üzemi épület Rákosliget',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/gephaz_acelszerkezeti_es_felujitasi_munkai.jpg'),
      thumbnail: require('../images/thumbnail-gephaz_acelszerkezeti_es_felujitasi_munkai.jpg'),
      title: 'Gépház acélszerkezeti és felújítási munkái',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/mav-uzemi-epulet.jpg'),
      thumbnail: require('../images/thumbnail-mav-uzemi-epulet.jpg'),
      title: 'MÁV üzemi épület',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/hatvan-uzemi-epulet.jpg'),
      thumbnail: require('../images/thumbnail-hatvan-uzemi-epulet.jpg'),
      title: 'Hatvan üzemi épület',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/hatvan-fc-regi-lelato-bontasa.jpg'),
      thumbnail: require('../images/thumbnail-hatvan-fc-regi-lelato-bontasa.jpg'),
      title: 'Hatvan FC régi lelátó bontása',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/azbesztmentesites.jpg'),
      thumbnail: require('../images/thumbnail-azbesztmentesites.jpg'),
      title: 'Azbesztmentesítés',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/csarnokkivitelezes.jpg'),
      thumbnail: require('../images/thumbnail-csarnokkivitelezes.jpg'),
      title: 'Csarnokkivitelezés',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/csarnok-y-szektor-kft.jpg'),
      thumbnail: require('../images/thumbnail-csarnok-y-szektor-kft.jpg'),
      title: 'Csarnok, Y-Szektor Kft.',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/epuletbontas-rakoscsaba-ujtelep.jpg'),
      thumbnail: require('../images/thumbnail-epuletbontas-rakoscsaba-ujtelep.jpg'),
      title: 'Épületbontás Rákoscsaba - Újtelep',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/IMG_1051.jpg'),
      thumbnail: require('../images/thumbnail-IMG_1051.jpg'),
      title: '',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/IMG_1053.jpg'),
      thumbnail: require('../images/thumbnail-IMG_1053.jpg'),
      title: '',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/IMG_1058.jpg'),
      thumbnail: require('../images/thumbnail-IMG_1058.jpg'),
      title: '',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/acelszerkezet_bontas.jpg'),
      thumbnail: require('../images/thumbnail-acelszerkezet_bontas.jpg'),
      title: 'Acélszerkezet bontás',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/termelouzem_iroda_kialakitas.jpg'),
      thumbnail: require('../images/thumbnail-termelouzem_iroda_kialakitas.jpg'),
      title: 'Termelőüzem + iroda kialakítás',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/termelouzem_iroda_kialakitas_2.jpg'),
      thumbnail: require('../images/thumbnail-termelouzem_iroda_kialakitas_2.jpg'),
      title: 'Termelőüzem + iroda kialakítás',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/csarnokepulet-alapozas-tahi.jpg'),
      thumbnail: require('../images/thumbnail-csarnokepulet-alapozas-tahi.jpg'),
      title: 'Csarnoképület alapozás Tahi',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/felveteli-epuulet-reszleges-bontasa-dorog.jpg'),
      thumbnail: require('../images/thumbnail-felveteli-epuulet-reszleges-bontasa-dorog.jpg'),
      title: 'Felvételi épület részleges bontása Dorog',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/acelszerkezetes-csarnok-kivitelezes-debrecen.jpg'),
      thumbnail: require('../images/thumbnail-acelszerkezetes-csarnok-kivitelezes-debrecen.jpg'),
      title: 'Acélszerkezetes csarnok kivitelezés Debrecen',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/elektronikai-uzem-kivitelezes.jpg'),
      thumbnail: require('../images/thumbnail-elektronikai-uzem-kivitelezes.jpg'),
      title: 'Elektronikai üzem kivitelezés',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/telepules-rekonstrukcio-kivitelezes.jpg'),
      thumbnail: require('../images/thumbnail-telepules-rekonstrukcio-kivitelezes.jpg'),
      title: 'Település rekonstrukció kivitelezés',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/egyedi-fa-acel-szerkezet-felujitas.jpg'),
      thumbnail: require('../images/thumbnail-egyedi-fa-acel-szerkezet-felujitas.jpg'),
      title: 'Egyedi fa- acél szerkezet felújítás MÁV Újpest megállóhely',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/epuletszarnyak-bontasa-veszelyes-hulladek-kezeles.jpg'),
      thumbnail: require('../images/thumbnail-epuletszarnyak-bontasa-veszelyes-hulladek-kezeles.jpg'),
      title: 'Épületszárnyak bontása, veszélyes hulladék kezelés',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/gardony-felveteli-epulet-es-biztber-kialakitas-alvallalkozasban.jpg'),
      thumbnail: require('../images/thumbnail-gardony-felveteli-epulet-es-biztber-kialakitas-alvallalkozasban.jpg'),
      title: 'Gárdony felvételi épület és biztber kialakítás alvállalkozásban',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/lakoepulet-bontas-azbesztmentesitessel.jpg'),
      thumbnail: require('../images/thumbnail-lakoepulet-bontas-azbesztmentesitessel.jpg'),
      title: 'Lakóépület bontás azbesztmentesítéssel',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/lg-klima-projekmenedzsment.jpg'),
      thumbnail: require('../images/thumbnail-lg-klima-projekmenedzsment.jpg'),
      title: 'LG klíma projektmenedzsment',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/peronteto-acel-szerkezet.jpg'),
      thumbnail: require('../images/thumbnail-peronteto-acel-szerkezet.jpg'),
      title: 'Perontető acél szerkezet',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/szekesfehervar-biztber-kialakitas-resz-projektmendzsment.jpg'),
      thumbnail: require('../images/thumbnail-szekesfehervar-biztber-kialakitas-resz-projektmendzsment.jpg'),
      title: 'Székesfehérvár biztber kialakítás rész-projektmendzsment',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/szekesfehervar-biztber-projektmenedzsment.jpg'),
      thumbnail: require('../images/thumbnail-szekesfehervar-biztber-projektmenedzsment.jpg'),
      title: 'Székesfehérvár biztber projektmenedzsment',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/tetofelujitas.jpg'),
      thumbnail: require('../images/thumbnail-tetofelujitas.jpg'),
      title: 'Tetőfelújítás',
      width: 1920,
      height: 1440
    },
    {
      img: require('../images/uzemi-epulet-gepi-bontasa.jpg'),
      thumbnail: require('../images/thumbnail-uzemi-epulet-gepi-bontasa.jpg'),
      title: 'Üzemi épület gépi bontása',
      width: 1920,
      height: 1440
    }
  ]
}
