import React from 'react';
import { Route, Link } from 'react-router-dom';

class HeaderComponent extends React.Component {
  render() {
    return (
      <header>
        <div className="title container-fluid center">
          <h1>Szinán 21 Kft</h1>
          <p>kivitelezés-projektmenedzsment</p>
        </div>
        <nav className="container-fluid center">
          <ul className="nav nav-pills">
            <CustomNavLink to="/" exact={true} label="Cégünkről" />
            <CustomNavLink to="/galeria" label="Galéria" />
            <CustomNavLink to="/kapcsolat" label="Kapcsolat" />
          </ul>
        </nav>
      </header>
    );
  }
}

const CustomNavLink = ({ to, exact, label }) => (
  <Route path={to} exact={exact} children={({ match }) => (
    <li role="presentation" className={match ? 'active' : ''}><Link to={to}>{label}</Link></li>
  )}/>
)

HeaderComponent.defaultProps = {
};

export default HeaderComponent;
